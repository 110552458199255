var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('step-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" My Business Information ")]},proxy:true},{key:"body",fn:function(){return [_c('ValidationObserver',{ref:"businessData",attrs:{"slim":""}},[_c('div',{staticClass:"business-data"},[_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"errors":errors,"is-required":""},model:{value:(_vm.businessName),callback:function ($$v) {_vm.businessName=$$v},expression:"businessName"}},[_vm._v(" business name ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"errors":errors,"is-required":""},model:{value:(_vm.fullAddress),callback:function ($$v) {_vm.fullAddress=$$v},expression:"fullAddress"}},[_vm._v(" full address ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"errors":errors,"is-required":""},model:{value:(_vm.taxId),callback:function ($$v) {_vm.taxId=$$v},expression:"taxId"}},[_vm._v(" tax ID ")])]}}])}),_c('app-tel-input',{model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_vm._v(" phone ")]),_c('ValidationProvider',{attrs:{"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"errors":errors,"is-required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_vm._v(" email ")])]}}])}),_c('app-text-input',{model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}},[_vm._v(" website ")])],1)])]},proxy:true},{key:"controls",fn:function(){return [_c('app-step-controls',{attrs:{"show-reset":""},on:{"reset":_vm.resetBusinessInfo,"next-step":_vm.nextStep},scopedSlots:_vm._u([{key:"reset",fn:function(){return [_vm._v("Start with new business info")]},proxy:true},{key:"ok",fn:function(){return [_vm._v("Next")]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }