<template>
  <div class="invoice">
    <transition name="fade" mode="out-in">
      <business-information
        v-if="step === 'business'"
        @next="nextStep"
        key="1"
      />
      <billing-information
        v-if="step === 'billing'"
        @next="nextStep"
        key="2"
      />
      <notes-and-terms
        v-if="step === 'terms'"
        @next="nextStep"
        key="3"
      />
      <final-step
        v-if="step === 'final'"
        @next="nextStep"
        key="4"
      />
    </transition>
  </div>
</template>

<script>
import BusinessInformation from '@/components/BusinessInformation';
import BillingInformation from '@/components/BillingInformation';
import NotesAndTerms from '@/components/NotesAndTerms';
import FinalStep from "@/components/FinalStep";

export default {
  name: 'Invoice',
  components: {
    BusinessInformation,
    BillingInformation,
    NotesAndTerms,
    FinalStep,
  },
  data() {
    return {
      step: 'business'
    }
  },
  methods: {
    nextStep(step) {
      if(step !== 'submit') {
        this.step = step;
      } else {
        this.submitData();
      }
    },
    submitData() {
      this.step = 'business';
    }
  }
}
</script>

<style lang="scss" scoped>
  .invoice {
    margin-bottom: 1.5rem;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
