<template>
  <step-card>
    <template v-slot:header>
      Billing Information
    </template>
    <template v-slot:body>
      <ValidationObserver ref="billingData" slim>
        <div class="billing-information">
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <app-text-input v-model="billTo" :errors="errors" is-required>
              Bill To
            </app-text-input>
          </ValidationProvider>
          <app-text-input v-model="cc">
            CC
          </app-text-input>
          <app-text-input v-model="reference" placeholder="Such as PO#">
            Invoice No.
          </app-text-input>
          <app-date-time v-model="invoiceDate">
            invoice date
          </app-date-time>
          <app-date-time v-model="dueDate">
            due date
          </app-date-time>
          <app-multiselect
            v-model="invoiceCurrency"
            :options="currencyList"
            :allow-empty="false"
            :searchable="false"
            deselect-label="Selected"
          >
            <template v-slot:control-label>
              invoice currency
            </template>
          </app-multiselect>
        </div>
        <div class="invoice">
          <div v-for="(n, idx) in services.length" class="invoice-row" :key="idx">
            <ValidationProvider rules="required" v-slot="{ errors }" slim>
              <app-text-input
                v-model="services[n-1].description"
                :no-label="idx !== 0"
                :errors="errors"
                is-required
              >
                Description
              </app-text-input>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }" slim>
              <app-currency-input
                v-model="services[n-1].amount"
                :currency="invoiceCurrency"
                :precision="2"
                locale="en"
                :no-label="idx !== 0"
                :errors="errors"
                is-required
              >
                Amount
              </app-currency-input>
            </ValidationProvider>
            <app-button-ghost v-if="services.length > 1" @click="services.splice(idx, 1)">
              <component :is="'cross-icon'" />
            </app-button-ghost>
          </div>
          <div class="add-row-control">
            <app-button-ghost @click="addRow">
              <component :is="'circle-plus-icon'" class="prepend" />
              Add another item
            </app-button-ghost>
          </div>
          <div class="divider" />
          <div class="result">
            <div class="result-row">
              <div class="description description--subtotal">
                Subtotal
              </div>
              <div class="amount amount--subtotal">
                <span v-html="currencySymbol"></span> {{ sumTotal | roundValue }}
              </div>
            </div>
            <div class="result-row">
              <div class="description">
                Total
              </div>
              <div class="amount">
                <span v-html="currencySymbol"></span> {{ sumTotal | roundValue }}
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </template>
    <template v-slot:controls>
      <app-step-controls
        @prev-step="$emit('next', 'business')"
        @next-step="nextStep"
        show-back
      >
        <template v-slot:back>Step Back</template>
        <template v-slot:ok>Next</template>
      </app-step-controls>
    </template>
  </step-card>
</template>

<script>
import Repository from '@/api/RepositoryFactory';
import { mapState } from 'vuex';
import StepCard from "@/components/StepCard";
import AppStepControls from "@/components/ui/AppStepControls";
import AppButtonGhost from "@/components/ui/AppButtonGhost";
import AppTextInput from "@/components/ui/AppTextInput";
import AppCurrencyInput from "@/components/ui/AppCurrencyInput";
import AppDateTime from "@/components/ui/AppDateTime";
import AppMultiselect from '@/components/ui/AppMultiselect';

const ContentRepository = Repository.get('content');

export default {
  name: 'BillingInformation',
  components: {
    StepCard,
    AppTextInput,
    AppDateTime,
    AppCurrencyInput,
    AppButtonGhost,
    AppStepControls,
    AppMultiselect,
  },
  data() {
    return {
      currencyList: ['USD', 'EUR'],
      services: [
        {
          description: '',
          amount: null
        }
      ]
    }
  },
  created() {
    if(this.invoiceData !== null) {
      this.services = this.invoiceData;
    }
  },
  computed: {
    ...mapState({
      uuid: state => state.uuid,
      invoiceData: state => state.billingData.items,
      billingObj: state => state.billingData,
    }),
    billTo: {
      get() {
        return this.$store.state.billingData.bill_to;
      },
      set(value) {
        this.$store.commit('SET_BILL_TO', value);
      }
    },
    cc: {
      get() {
        return this.$store.state.billingData.bill_to_cc;
      },
      set(value) {
        this.$store.commit('SET_CC', value);
      }
    },
    invoiceDate: {
      get() {
        return this.$store.state.billingData.invoice_date
          ? new Date(this.$store.state.billingData.invoice_date)
          : new Date();
      },
      set(value) {
        this.$store.commit('SET_INVOICE_DATE', value);
      }
    },
    dueDate: {
      get() {
        return this.$store.state.billingData.due_date
          ? new Date(this.$store.state.billingData.due_date)
          : new Date();
      },
      set(value) {
        this.$store.commit('SET_DUE_DATE', value);
      }
    },
    reference: {
      get() {
        return this.$store.state.billingData.invoice_no;
      },
      set(value) {
        this.$store.commit('SET_INVOICE_NO', value);
      }
    },
    invoiceCurrency: {
      get() {
        return this.$store.state.billingData.invoice_currency;
      },
      set(value) {
        this.$store.commit('SET_INVOICE_CURRENCY', value);
      }
    },
    sumTotal() {
      return this.services.reduce((sum, obj) => sum + obj.amount, 0);
    },
    currencySymbol() {
      return this.invoiceCurrency === 'USD' ? '&dollar;' : '&euro;';
    }
  },
  watch: {
    services: {
      deep: true,
      handler: function(newValue) {
        if(newValue) {
          this.$store.commit('SET_INVOICE_DATA', newValue);
        }
      }
    }
  },
  methods: {
    addRow() {
      this.services.push({ description: '', amount: null });
    },
    async updateInvoiceData() {
      try {
        const payload = {
          Raw: {
            ...this.billingObj
          }
        }
        const response = await ContentRepository.updateInvoice(this.uuid, payload);
        if (response.status === 200) {
          this.$emit('next', 'terms');
        }
      } catch (e) {
        console.log(e);
      }
    },
    nextStep() {
      this.$refs.billingData.validate().then(success => {
        if (!success) return;
        this.updateInvoiceData();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .billing-information {
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 4rem;
    row-gap: 1.5rem;
    @media only screen and (min-width: 568px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .invoice {
    &-row {
      padding-right: 1rem;
      margin-bottom: 1.5rem;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
      column-gap: 2rem;
      position: relative;
      @media only screen and (min-width: 568px) {
        padding-right: initial;
        grid-template-columns: 3fr 1fr;
      }
      .app-btn-ghost {
        position: absolute;
        bottom: 1.2rem;
        right: -1rem;
        @media only screen and (min-width: 568px) {
          right: -2.2rem;
        }
        svg {
          width: 1.4rem;
        }
      }
    }
  }
  .add-row-control {
    margin-bottom: 0.8rem;
  }
  .divider {
    margin-bottom: 1.4rem;
    height: 0.1rem;
    background-color: #F1F1F1;
  }
  .result {
    text-align: right;
    font-size: 1.6rem;
    &-row {
      display: flex;
      justify-content: flex-end;
      &:not(:last-of-type) {
        margin-bottom: 1.2rem;
      }
    }
  }
  .description {
    flex-grow: 1;
    font-family: $proximaSemibold;
    color: #2F2F2F;
    &--subtotal {
      font-family: $proxima;
      color: #AEAEAE;
    }
  }
  .amount {
    min-width: 10rem;
    font-family: $proximaSemibold;
    color: #2F2F2F;
    &--subtotal {
      font-family: $proxima;
      color: #AEAEAE;
    }
  }
</style>
