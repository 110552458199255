<template>
  <div class="control-area">
    <app-button-ghost
      v-if="showReset"
      @click="$emit('reset')"
      :disabled="disabled"
    >
      <component :is="'reset-icon'" class="prepend"></component>
      <slot name="reset"></slot>
    </app-button-ghost>
    <app-button-ghost
      v-if="showBack && !showReset"
      @click="$emit('prev-step')"
      :disabled="disabled"
    >
      <component :is="'arrow-back-icon'" class="prepend"></component>
      <slot name="back"></slot>
    </app-button-ghost>
    <app-button
      :disabled="disabled"
      @click="$emit('next-step')"
    >
      <slot name="ok"></slot>
    </app-button>
  </div>
</template>

<script>
import AppButton from "@/components/ui/AppButton";
import AppButtonGhost from "@/components/ui/AppButtonGhost";

export default {
  name: 'AppStepControls',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppButton,
    AppButtonGhost,
  }
}
</script>

<style lang="scss" scoped>
  .control-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .app-btn-ghost {
      margin-bottom: 2rem;
      @media only screen and (min-width: 768px) {
        margin-bottom: initial;
        position: absolute;
        left: 0;
        top: initial;
      }
      svg {
        width: 1.4rem;
      }
    }
  }
</style>
