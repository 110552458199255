import GeneralRepository from './GeneralRepository';
import ContentRepository from './ContentRepository';

const repositories = {
  general: GeneralRepository,
  content: ContentRepository
};

export default {
  get: (name) => repositories[name],
};
