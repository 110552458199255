<template>
  <div class="date-input">
    <span class="date-input__label">
      <slot></slot>
    </span>
    <div class="app-date-time">
      <div class="app-date-time__prepend">
        <components :is="`${prependIcon}-icon`" class="append" />
      </div>
      <date-picker
        v-model="pickedDateTime"
        v-bind="$attrs"
        value-type="date"
        :clearable="false"
        :editable="false"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "AppDateTime",
  components: {
    DatePicker,
  },
  props: {
    value: {
      required: true,
    },
    prependIcon: {
      type: String,
      default: 'calendar',
    }
  },
  computed: {
    pickedDateTime: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.date-input {
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
}
.app-date-time {
  display: flex;
  &__prepend {
    width: 4.8rem;
    height: 4.8rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $control-bg;
    border-radius: 0.4rem 0 0 0.4rem;
    border: 0.1rem solid $control-border;
    svg {
      display: block;
      width: 2rem;
      height: auto;
      fill: $blue;
    }
  }
}
.mx-datepicker {
  width: 100%;
  max-width: 32rem;
}
/deep/ .mx-input-wrapper {
  .mx-input {
    height: 4.8rem;
    padding: 0.6rem 1rem !important;
    border-radius: 0 0.4rem 0.4rem 0;
    border-color: $control-border;
    background-color: $control-bg;
    box-shadow: none;
    cursor: pointer;
  }
  .mx-icon-calendar {
    display: none !important;
  }
}
</style>
