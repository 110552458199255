<template>
  <div class="final-step">
    <step-card>
      <template v-slot:header>
        <div class="success-header">
          <component :is="'success-icon'"></component>
          Invoice Generated
        </div>
      </template>
      <template v-slot:body>
        <div
          v-if="generatedData !== null"
          class="download-invoice"
        >
          <a :href="generatedData.Path" target="_blank">
            <component :is="'download-icon'"></component>
            download copy.
          </a>
        </div>
      </template>
    </step-card>
    <app-step-controls @next-step="nextStep">
      <template v-slot:ok>Start new Invoice</template>
    </app-step-controls>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StepCard from "@/components/StepCard";
import AppStepControls from "@/components/ui/AppStepControls";

export default {
  name: "FinalStep",
  components: {
    StepCard,
    AppStepControls
  },
  computed: {
    ...mapState({
      generatedData: state => state.generatedData,
    })
  },
  methods: {
    nextStep() {
      this.$store.dispatch('clearBusinessData');
      this.$emit('next', 'business');
    }
  }
}
</script>

<style lang="scss" scoped>
  .final-step {
    /deep/ .step-card {
      padding: 6rem 6rem 7.5rem;
      margin-bottom: 4rem;
      &__header {
        margin-bottom: 7.5rem;
      }
      &__body {
        margin-bottom: 0;
      }
    }
  }
  .success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $proximaSemibold;
    font-size: 2.4rem;
    svg {
      margin-bottom: 2rem;
      fill: $blue;
      width: 8rem;
    }
  }
  .download-invoice {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    padding: 2rem;
    a {
      font-family: $proximaSemibold;
      color: $blue;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      display: inline-flex;
      align-items: center;
      svg {
        fill: $blue;
        display: block;
        width: 2rem;
        height: auto;
        margin-right: 1.2rem;
      }
    }
  }
</style>
