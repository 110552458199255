<template>
  <div class="step-card">
    <h2 class="step-card__header">
      <slot name="header"></slot>
    </h2>
    <div class="step-card__body">
      <slot name="body"></slot>
    </div>
    <div class="step-card__controls">
      <slot name="controls"></slot>
    </div>
    <slot name="errors"></slot>
  </div>
</template>

<script>
export default {
  name: 'StepCard'
}
</script>

<style lang="scss" scoped>
  .step-card {
    width: 100%;
    max-width: 86.7rem;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    background: #FFFFFF;
    box-shadow: 0 0 4.4rem rgba(0, 0, 0, 0.15);
    @media only screen and (min-width: 768px) {
      padding: 4rem;
    }
    &__header {
      margin: 0 0 3rem;
      font-family: $proximaSemibold;
      font-size: 2.4rem;
      line-height: 1.2;
    }
    &__body {
      margin-bottom: 4rem;
    }
    &__controls {
      display: flex;
      justify-content: center;
    }
  }
</style>
