<template>
  <div class="invoice-page">
    <page-header />
    <invoice />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Invoice from "@/components/Invoice";

export default {
  name: 'InvoiceView',
  components: {
    PageHeader,
    Invoice
  }
}
</script>

<style lang="scss" scoped>
  .invoice-page {
    padding: 0 1.5rem;
  }
</style>
