<template>
  <label class="app-text-input">
    <span
      v-if="!noLabel"
      class="app-text-input__label"
    >
      <slot></slot>
    </span>
    <textarea
      class="app-text-input__control"
      :value="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
name: 'AppTextArea',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-text-input {
  width: 100%;
  display: inline-block;
  position: relative;
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
  &__control {
    width: 100%;
    min-height: 4.8rem;
    border-radius: 0.4rem;
    padding: 1rem 1.5rem;
    border: 0.1rem solid $control-border;
    background-color: $control-bg;
    resize: none;
    height: 16rem;
    &:focus {
      outline: 0;
    }
  }
}
</style>
