import apiClient from './apiClient';

const invoice = '/content/:uuid/invoice';
const upload = '/content/:uuid/upload';
const generate = '/invoice/:uuid/generate';

export default {
  updateInvoice(uuid, payload) {
    return apiClient.post(`${invoice}`.replace(':uuid', uuid), payload);
  },
  updateUpload(uuid, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return apiClient.post(`${upload}`.replace(':uuid', uuid), payload, config);
  },
  generateInvoice(uuid) {
    return apiClient.post(`${generate}`.replace(':uuid', uuid));
  },
};
