<template>
  <div class="app-select">
    <label
      class="app-select__label"
      v-if="!noLabel"
    >
      <slot name="control-label"></slot>
    </label>
    <Multiselect
      v-model="selected"
      v-bind="$attrs"
      v-on="selectListeners"
      :label="label"
      :track-by="trackBy"
      :options="options"
      :class="{ 'multiselect--rounded': rounded }"
    >
      <!-- eslint-disable-next-line -->
      <slot name="singleLabel" slot="singleLabel" slot-scope="props"></slot>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "AppMultiselect",
  inheritAttrs: false,
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    trackBy: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    rounded: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    selectListeners() {
      // eslint-disable-next-line
      const { input, ...listeners } = this.$listeners;
      return listeners;
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.app-select {
  width: 100%;
  display: inline-block;
  position: relative;
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
}

/deep/ .multiselect {
  min-height: 48px;
  &--rounded {
    .multiselect__tags {
      border-radius: 999px;
      background-color: #ffffff;
    }
    .multiselect__single {
      background-color: #ffffff;
    }
    .multiselect__tags {
      background-color: #ffffff;
    }
    .multiselect__input {
      background-color: #ffffff;
    }
  }
  &__select {
    height: 46px;
  }
  &__tags {
    min-height: 48px;
    border-radius: 4px;
    border-color: $control-border;
    background-color: $control-bg;
    padding-left: 20px;
  }
  &__single {
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $control-bg;
  }
  &__input {
    min-height: 30px;
    line-height: 30px;
    background-color: $control-bg;
  }
  &__option {
    overflow: hidden;
    text-overflow: ellipsis;
    &--selected {
      &.multiselect__option--highlight {
        color: inherit;
        background: $control-border;
        pointer-events: none;
        &:after {
          background: $control-border;
          color: inherit;
        }
      }
    }
    &--highlight {
      background: $blue;
      &:after {
        background: $blue;
      }
    }
  }
}
</style>
