<template>
  <header class="header">
    <h1>Simple Payable Invoice</h1>
    <p class="tagline">
      no ads <span /> no registration <span /> free <span /> instantly payable
    </p>
  </header>
</template>

<script>
export default {
name: "PageHeader"
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  padding: 4rem 0;
  font-family: $proximaBold;
  h1 {
    margin: 0 0 2rem;
    font-size: 4.8rem;
    line-height: 1.2;
    text-align: center;
  }
  p {
    margin: 0;
  }
}
.tagline {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 1;
  color: $blue;
  text-transform: uppercase;
  text-align: center;
  span {
    margin: 0 0.5rem;
    width: 0.4rem;
    height: 0.4rem;
    display: inline-block;
    background-color: $black;
    border-radius: 50%;
  }
}

</style>
