<template>
  <button
    @click="$emit('click')"
    v-bind="$attrs"
    class="app-btn"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton'
}
</script>

<style lang="scss" scoped>
  .app-btn {
    height: 5.4rem;
    min-width: 12.7rem;
    padding: 0 4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-family: $proximaSemibold;
    color: #FFFFFF;
    line-height: 1;
    letter-spacing: 0.2rem;
    border: none;
    background-color: $blue;
    transition: all .2s ease-in-out;
    border-radius: 99rem;
    text-transform: uppercase;
    cursor: pointer;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(130,138,145,.3);
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    svg {
      fill: #FFFFFF;
    }
    &-ghost {
    }
  }
  svg {
    display: block;
    width: 20px;
    height: auto;
    flex-shrink: 0;
    margin-right: 7px;
  }
</style>
