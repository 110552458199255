<template>
  <step-card>
    <template v-slot:header>
      My Business Information
    </template>
    <template v-slot:body>
      <ValidationObserver ref="businessData" slim>
        <div class="business-data">
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <app-text-input v-model="businessName" :errors="errors" is-required>
              business name
            </app-text-input>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <app-text-input v-model="fullAddress" :errors="errors" is-required>
              full address
            </app-text-input>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <app-text-input v-model="taxId" :errors="errors" is-required>
              tax ID
            </app-text-input>
          </ValidationProvider>
          <app-tel-input v-model="phone">
            phone
          </app-tel-input>
          <ValidationProvider rules="required|email" v-slot="{ errors }" slim>
            <app-text-input v-model="email" :errors="errors" is-required>
              email
            </app-text-input>
          </ValidationProvider>
          <app-text-input v-model="website">
            website
          </app-text-input>
        </div>
      </ValidationObserver>
    </template>
    <template v-slot:controls>
      <app-step-controls
        @reset="resetBusinessInfo"
        @next-step="nextStep"
        show-reset
      >
        <template v-slot:reset>Start with new business info</template>
        <template v-slot:ok>Next</template>
      </app-step-controls>
    </template>
  </step-card>
</template>

<script>
import Repository from '@/api/RepositoryFactory';
import { mapState } from 'vuex';
import StepCard from "@/components/StepCard";
import AppTextInput from "@/components/ui/AppTextInput";
import AppStepControls from "@/components/ui/AppStepControls";
import AppTelInput from "@/components/ui/AppTelInput";

const GeneralRepository = Repository.get('general');

export default {
  name: 'BusinessInformation',
  components: {
    StepCard,
    AppTextInput,
    AppStepControls,
    AppTelInput,
  },
  data() {
    return {}
  },
  created() {
    this.$store.commit('SET_GENERATED_DATA', null);
  },
  computed: {
    ...mapState({
      uuid: state => state.uuid,
      businessObj: state => state.businessData,
    }),
    businessName: {
      get() {
        return this.$store.state.businessData.business_name;
      },
      set(value) {
        this.$store.commit('SET_BUSINESS_NAME', value);
      }
    },
    fullAddress: {
      get() {
        return this.$store.state.businessData.full_address;
      },
      set(value) {
        this.$store.commit('SET_FULL_ADDRESS', value);
      }
    },
    taxId: {
      get() {
        return this.$store.state.businessData.tax_id;
      },
      set(value) {
        this.$store.commit('SET_TAX_ID', value);
      }
    },
    phone: {
      get() {
        return this.$store.state.businessData.phone;
      },
      set(value) {
        this.$store.commit('SET_PHONE', value);
      }
    },
    email: {
      get() {
        return this.$store.state.businessData.email;
      },
      set(value) {
        this.$store.commit('SET_EMAIL', value);
      }
    },
    website: {
      get() {
        return this.$store.state.businessData.website;
      },
      set(value) {
        this.$store.commit('SET_WEBSITE', value);
      }
    },
  },
  methods: {
    async newRegistration() {
      try {
        const payload = {
          ...this.businessObj
        }
        const response = await GeneralRepository.register(payload);
        if (response.status === 200) {
          this.$store.commit('SET_UUID', response.data.UUID);
          this.$emit('next', 'billing');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async updateRegistration() {
      try {
        const payload = {
          ...this.businessObj
        }
        const response = await GeneralRepository.updRegistration(payload, this.uuid);
        if (response.status === 200) {
          this.$emit('next', 'billing');
        }
      } catch (e) {
        console.log(e);
      }
    },
    nextStep() {
      this.$refs.businessData.validate().then(success => {
        if (!success) return;
        if (this.uuid === null) {
          this.newRegistration();
        } else {
          this.updateRegistration();
        }
      });
    },
    resetBusinessInfo() {
      this.$refs.businessData.reset()
      localStorage.removeItem('vuex');
      const state = {
        uuid: null,
        businessData: {
          business_name: '',
          full_address: '',
          tax_id: '',
          phone: '',
          email: '',
          website: '',
        },
        billingData: {
          invoice_no: '',
          invoice_currency: 'USD',
          invoice_date: new Date(),
          due_date: new Date(),
          bill_to: '',
          bill_to_cc: '',
          items: null,
          notes_recipient: '',
          terms: '',
          attachments: []
        },
        generatedData: null,
      };
      this.$store.replaceState(state);
    }
  }
}
</script>

<style lang="scss" scoped>
  .business-data {
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 4rem;
    row-gap: 1.5rem;
    @media only screen and (min-width: 568px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .time-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 4rem;
    row-gap: 1.5rem;
  }
</style>
