<template>
  <label
    class="app-currency-input"
  >

    <span
      v-if="!noLabel"
      class="app-currency-input__label"
    >
      <slot></slot><span v-if="isRequired" class="required">*</span>
    </span>
    <currency-input
      class="app-currency-input__control"
      :class="{ 'has-error': errors.length > 0 }"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      @change="updateValue"
    />
    <span class="error-message">{{ errors[0] }}</span>
  </label>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'

export default {
  name: "AppCurrencyInput",
  inheritAttrs: false,
  components: {
    CurrencyInput,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-currency-input {
  width: 100%;
  display: inline-block;
  position: relative;
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
  &__control {
    width: 100%;
    min-height: 4.8rem;
    border-radius: 0.4rem;
    padding: 0 1.5rem;
    border: 0.1rem solid $control-border;
    background-color: $control-bg;
    &:focus {
      outline: 0;
    }
    &.has-error {
      border-color: $danger;
    }
  }
}
.required {
  color: $danger;
}
.error-message {
  font-size: 1.2rem;
  white-space: nowrap;
  color: $danger;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}
</style>
