<template>
  <step-card>
    <template v-slot:header>
      Notes and Terms
    </template>
    <template v-slot:body>
      <div class="notes">
        <app-text-area v-model="notesData">
          Note to recipient
        </app-text-area>
        <app-text-area v-model="termsData">
          Terms and conditions
        </app-text-area>
      </div>
      <div class="attachment">
        <div class="upload-area">
          <label class="file-upload">
            <component :is="'clip-icon'" />
            Attach file
            <input style="visibility: hidden" type="file" ref="file" v-on:change="handleFileUpload()" :disabled="uploading">
          </label>
          <p class="file-info" v-if="attachments.length">
            <span v-for="(attachment, idx) in attachments" :key="idx" :style="{backgroundImage: `url(${attachment})` }">
              {{ attachment | getImageName }}
            </span>
          </p>
        </div>
        <p><span v-if="uploading">Uploading file ...</span></p>
      </div>
    </template>
    <template v-slot:controls>
      <app-step-controls
        @prev-step="$emit('next', 'billing')"
        @next-step="sendInvoice"
        show-back
        :disabled="generating || uploading"
      >
        <template v-slot:back>Step Back</template>
        <template v-slot:ok>Send Invoice</template>
      </app-step-controls>
    </template>
    <template v-slot:errors>
      <div class="error">
        {{ errorMessage }}
      </div>
    </template>
  </step-card>
</template>

<script>
import Repository from '@/api/RepositoryFactory';
import { mapState } from 'vuex';
import StepCard from "@/components/StepCard";
import AppStepControls from "@/components/ui/AppStepControls";
import AppTextArea from "@/components/ui/AppTextArea";

const ContentRepository = Repository.get('content');

export default {
  name: 'NotesAndTerms',
  components: {
    StepCard,
    AppStepControls,
    AppTextArea,
  },
  data() {
    return {
      attachment: null,
      uploading: false,
      generating: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapState({
      uuid: state => state.uuid,
      attachments: state => state.billingData.attachments,
      billingObj: state => state.billingData,
    }),
    notesData: {
      get() {
        return this.$store.state.billingData.notes_recipient;
      },
      set(value) {
        this.$store.commit('SET_NOTES', value);
      }
    },
    termsData: {
      get() {
        return this.$store.state.billingData.terms;
      },
      set(value) {
        this.$store.commit('SET_TERMS', value);
      }
    },
  },
  methods: {
    async handleFileUpload() {
      try {
        this.uploading = true;
        let formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        const response = await ContentRepository.updateUpload(this.uuid, formData);
        if(response.status === 200) {
          console.log(response.data.path);
          this.$store.commit('SET_ATTACHMENTS', response.data.path);
        }
        this.uploading = false;
      } catch (e) {
        console.log(e);
        this.uploading = false;
      }
    },
    async updateInvoiceData() {
      this.generating = true;
      this.errorMessage = '';
      try {
        const payload = {
          Raw: {
            ...this.billingObj
          }
        }
        const response = await ContentRepository.updateInvoice(this.uuid, payload);
        if (response.status === 200) {
          await this.submitInvoice();
          this.$emit('next', 'final');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async submitInvoice() {
      try {
        const response = await ContentRepository.generateInvoice(this.uuid);
        if (response.status === 200) {
          this.$store.commit('SET_GENERATED_DATA', response.data);
          this.generating = false;
        }
      } catch (e) {
        this.errorMessage = `Try again later: ${e.message}`;
        this.generating = false;
      }
    },
    sendInvoice() {
      this.updateInvoiceData();
    }
  },
  filters: {
    getImageName(value) {
      if (!value) return ''
      const m = value.split('/');
      return m[m.length - 1];
    }
  }
}
</script>

<style lang="scss" scoped>
  .notes {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 2rem;
    @media only screen and (min-width: 568px) {
      gap: 4rem;
      grid-template-columns: 1fr 1fr;
    }
  }
  .attachment {
    color: $blue;
  }
  .upload-area {
    display: flex;
    align-items: center;
    .file-upload {
      margin-right: 1.6rem;
    }
  }
  .file-upload {
    width: 12rem;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    svg {
      display: block;
      width: 2rem;
      height: auto;
      margin-right: 0.8rem;
      flex-shrink: 0;
      fill: $blue;
      transition: all .2s ease-in-out;
    }
  }
  .file-info {
    margin: 0;
    color: $blue-secondary;
    font-size: 1.4rem;
    line-height: 1;
    span {
      margin-right: 1rem;
    }
  }
  .error {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 2rem;
    color: red;
  }
</style>
