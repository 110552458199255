<template>
  <button
    class="app-btn-ghost"
    @click="$emit('click')"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButtonGhost'
}
</script>

<style lang="scss" scoped>
  .app-btn-ghost {
    display: inline-flex;
    align-items: center;
    padding: 0.4rem 0;
    border: none;
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 1;
    color: $blue;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: darken($blue, 10%);
      svg {
        fill: darken($blue, 10%);
      }
    }
    &:focus {
      outline: 0;
    }
    svg {
      display: block;
      width: 2rem;
      height: auto;
      flex-shrink: 0;
      fill: $blue;
      transition: all .2s ease-in-out;
    }
    .prepend {
      margin-right: 0.6rem;
    }
    .append {
      margin-left: 1.2rem;
    }
  }
</style>
