<template>
  <div class="tel-input">
    <span class="tel-input__label">
      <slot></slot>
    </span>
    <vue-tel-input
      v-model="pickedTel"
      :enabled-flags="true"
      :valid-characters-only="true"
      :preferred-countries="preferredCountries"
      :input-options="inputOptions"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: "AppTelInput",
  components: {
    VueTelInput,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      inputOptions: {
        showDialCode: true
      },
      preferredCountries: [
        'US', 'CA', 'IL', 'AU',
      ],
    };
  },
  computed: {
    pickedTel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tel-input {
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
}
/deep/ .vue-tel-input {
  box-shadow: none !important;
  border-color: $control-border !important;
  border-radius: 0.4rem;
  background-color: $control-bg;
  &:focus {
    border-color: $control-border;
  }
  .vti__dropdown {
    width: 6rem;
    border-right: 1px solid $control-border;
    &-list {
      width: 280px;
      &.below {
        top: 4.8rem;
      }
    }
  }
  .vti__selection {
    width: 5rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  .vti__input {
    height: 4.6rem;
    padding: 0.6rem 1rem !important;
    border-radius: 0 0.4rem 0.4rem 0;
    background-color: $control-bg;
  }
}
</style>
