<template>
  <label class="app-text-input">
    <span
      v-if="!noLabel"
      class="app-text-input__label"
    >
      <slot></slot><span v-if="isRequired" class="required">*</span>
    </span>
    <input
      type="text"
      class="app-text-input__control"
      :class="{ 'has-error': errors.length > 0 }"
      :value="value"
      v-bind="inputAttrs"
      v-on="inputListeners"
      @input="$emit('input', $event.target.value)"
    >
    <span class="error-message">{{ errors[0] }}</span>
  </label>
</template>

<script>
export default {
  name: 'AppTextInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    inputAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { value, ...attrs } = this.$attrs;
      return attrs;
    },
    inputListeners() {
      // eslint-disable-next-line no-unused-vars
      const { type, input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-text-input {
  width: 100%;
  display: inline-block;
  position: relative;
  &__label {
    display: inline-block;
    margin: 0 0 0.8rem 0;
    font-size: 1.6rem;
    font-family: $proxima;
    line-height: 1;
    color: $blue-secondary;
    text-transform: capitalize;
  }
  &__control {
    width: 100%;
    min-height: 4.8rem;
    border-radius: 0.4rem;
    padding: 0 1.5rem;
    border: 0.1rem solid $control-border;
    background-color: $control-bg;
    &:focus {
      outline: 0;
    }
    &.has-error {
      border-color: $danger;
    }
  }
}
.required {
  color: $danger;
}
.error-message {
  font-size: 1.2rem;
  white-space: nowrap;
  color: $danger;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}
</style>
