import apiClient from './apiClient';

const endpoint = '/service/new';
const updRegistration = '/service/:uuid';

export default {
  register(payload) {
    return apiClient.post(`${endpoint}`, payload);
  },
  updRegistration(payload, uuid) {
    return apiClient.post(`${updRegistration}`.replace(':uuid', uuid), payload)
  }
};
